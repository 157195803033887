import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import config from "../../../content/meta/config";

class Seo1 extends React.Component {
    
    render() {
        const { postTitle, postDescription } = this.props
        let keywords = ''
        console.log('--------seo---------')
        console.log(this.props.keywords)
        if(this.props.keywords !== undefined && this.props.keywords !== null) 
        {
            this.props.keywords.map((key, index) => {
                keywords += key + ' '
            })
        }
        const title = postTitle !== '' ? `${postTitle} - ${config.shortSiteTitle}` : config.siteTitle;
        const description = postDescription ? postDescription : config.siteDescription;
        const url = config.siteUrl + config.pathPrefix 
        const image = config.siteImage;
        return (
            <Helmet
                htmlAttributes={{
                    lang: config.siteLanguage,
                    prefix: "og: http://ogp.me/ns#"
                }}
                >
                {/* General tags */}
                <title>{title}</title>
                <meta name="description" content={description} />
                {/* OpenGraph tags */}
                <meta property="og:url" content={url} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content={image} />
                <meta property="og:type" content="website" />
                {/* Key words */}
                <meta name="keywords" content={keywords}></meta>
                {/* Favicon ... seems not working on Live site*/}
                <link rel="icon" href="favicon.ico" type="image/x-icon" />
                <link rel="shortcut icon" href="favicon.ico" type="image/x-icon" />
            </Helmet>
        )
    }
}

export default Seo1