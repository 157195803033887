import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { FadeLoader } from "react-spinners";
import 'bootstrap/dist/css/bootstrap.min.css';
const ReactMarkdown = require('react-markdown');
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import "../../theme/font-awesome.min.css";
import marked from 'marked';
import {credential, cryptr} from "../../../config";

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  generateShareIcon
} from 'react-share';


class ProductWithDetail extends React.Component {
  mounted = false;
  constructor(props) {
    super(props);
    this.state = {
      isShowModal: false,
      curImage: "",
      curImageIndex: 1,
      subImages: [],
      firtfeed: 0,
      imageloadfinished: 0,
      facebook: '',
      twitter: '',
      linkedin: '',
      special: '',
      similar: [],
      showmodal: false,
    };
    this.render_sub_images = this.render_sub_images.bind(this);
    this.onArrow = this.onArrow.bind(this);
    //this.feedState = this.feedState.bind(this);
    this.render_rating = this.render_rating.bind(this);
    this.renderTable = this.renderTable.bind(this);
    this.render_detail = this.render_detail.bind(this);
    this.changeCurImage = this.changeCurImage.bind(this);
    this.blankline = this.blankline.bind(this);
    this.nextline = this.nextline.bind(this);
  }

  render_rating() {
    let num = this.props.item.rating
    let max = num
    let rlt = ''
    for ( let i = 0 ; i < 5 ; i++ ) {
      if (max >= 1) {
        rlt += `<div class="color-r fa fa-star" style="color: #0098e4" ></div>`
      } else if (max >= 0.5) {
        rlt += `<div class="color-r fa fa-star-half-o" style="color: #0098e4" ></div>`
      } else {
        rlt += `<div class="color-r fa fa-star-o"></div>`
      }
      max -= 1
    }
    return rlt
  }

  nextline(mark) {
    let param = mark.split('<next/>')
    let rlt = param.map((item, key) => {
      return(
        <div key = {key}>
          <ReactMarkdown source={item}></ReactMarkdown>
        </div>
      )
    })
    return rlt;
  }

  blankline(mark) {
    let param = mark.split('<br/>')
    let rlt = param.map((item, key) => {
      return(
        <div key = {key} className="blankline">
          {this.nextline(item)}
          <br/>
        </div>
      )
    })
    return rlt;
  }

  render_detail(flag) {
    let rlt = this.props.item.detail
    if (flag === 1) {
      rlt = this.props.item.security
    }    
    if (rlt === undefined) {
      return ""
    }
    let param = rlt.split('<table></table>')
    if (param.length === 1) {
      return (
        this.blankline(param[0])
      )
    } else {
      return (
        <div>
          {this.blankline(param[0])}
          <div className="table-container">
            {this.renderTable()}
          </div>
          { this.blankline(param[1]) }
        </div>
      )
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }
  componentDidMount() {
    this.mounted = true;
    var self = this;
    import('react-leaf-carousel')
      .then((item) => {
        this.setState({
          special: item.default
        })
    })
    setTimeout(() => {
      if (self.mounted)
        self.setState({
          imageloadfinished: 1
        });
    }, 1500);
  }
  onArrow(e, direction) {
    const len = this.state.subImages.length;
    e.preventDefault();
    var curImageIndex = this.state.curImageIndex;
    curImageIndex -= direction;
    if (curImageIndex >= len) curImageIndex -= len;
    if (curImageIndex < 0) curImageIndex = this.state.subImages.length - 1;
    if (curImageIndex >= this.state.subImages.length) curImageIndex = 0;
    this.setState({
      curImageIndex: curImageIndex,
      curImage: this.state.subImages[curImageIndex].image
    });
  }

  renderTable() {
    if(this.props.item.table === undefined || this.props.item.table === null) {
      return <div></div>
    }
    if(this.props.item.table.length !== null && this.props.item.table.length !== undefined && this.props.item.table.length === 0) {
      return <div></div>
    }
    let table = this.props.item.table
    return (
      <table className="table table-striped">
        <thead>
          <tr>
            {
              table[0].cell.map((cell, index) => {
                return (
                  <th key={index}>{cell.item}</th>
                )
              })
            }
          </tr>
        </thead>
        <tbody>
          {
            table.map((ta, index) => {
              if (index !== 0) {
                return (
                  <tr key = {index}>
                    {
                      ta.cell.map((cell, iindex) => {
                        return (
                          <td key={iindex}>{cell.item}</td>
                        )
                      })
                    }
                  </tr>
                )
              }
            })
          }
        </tbody>
      </table>
    )
  }

  changeCurImage(e, index) {
    e.preventDefault();
    this.setState({
      curImageIndex: index,
      curImage: this.state.subImages[index].image
    })
  }

  render_sub_images() {
    //console.log('==============');
    //console.log(this.state.subImages);
    const len = this.state.subImages.length;
    if (len < 3) return;
    let sub1 = (this.state.curImageIndex + len-1) % len;
    let sub2 = this.state.curImageIndex;
    let sub3 = (this.state.curImageIndex + 1) % len;
    /* ZG: Re-wrote better 
    if (sub1 >= len) sub1 -= len;
    if (sub2 >= len) sub2 -= len;
    if (sub3 >= len) sub3 -= len;
    if (sub1 < 0) sub1 += len;
    if (sub2 < 0) sub2 += len;
    if (sub3 < 0) sub3 += len;
    */
    return (
      <div>
        <div className={!this.state.imageloadfinished ? "image-loading-spin" : "hidden"}>
          <FadeLoader className="spinner" />
          <p className="loadingproductdetail">Loading products...</p>
          <style jsx>{`
            .hidden {
              display: none;
            }
            .image-loading-spin {
              text-align: center;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            }
          `}</style>
        </div>
        <ul className={this.state.imageloadfinished ? "subImages" : "hidden"}>
          <li className="arrow">
            <a href="#" className="left" onClick={e => this.onArrow(e, -1)}>
              <i className="fa fa-angle-left" />
            </a>
          </li>
          <li className="image" key={sub1}>
            <a href="#" onClick={(e) => this.changeCurImage(e,sub1)}>
              <img src={this.state.subImages[sub1].image} />
            </a>
          </li>
          <li className="image" key={sub2}>
            <a href="#" onClick={(e) => this.changeCurImage(e,sub2)}>
              <img src={this.state.subImages[sub2].image} />
            </a>
          </li>
          <li className="image" key={sub3}>
            <a href="#" onClick={(e) => this.changeCurImage(e,sub3)}>
              <img src={this.state.subImages[sub3].image} />
            </a>
          </li>
          <li className="arrow">
            <a href="#" className="left" onClick={e => this.onArrow(e, 1)}>
              <i className="fa fa-angle-right" />
            </a>
          </li>
          <style jsx>{`
          
            .hidden {
              display: none;
            }
            .subImages {
              list-style: none;
              display: flex;
              justify-content: center;
              .arrow {
                display: flex;
                align-items: center;
                font-size: 50px;
              }
            }
            .image {
              border: 1px solid #ccc;
              width: 100px;
              height: 100px;
              margin: 0 10px;
              padding: 1px;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                max-width: 100%;
                max-height: 100%;
              }
            }
          `}</style>
        </ul>
      </div>
    );
  }
  componentWillReceiveProps(nextProps){
    if (nextProps.total !== undefined && nextProps.item !== undefined) {
      let sim = []
      nextProps.total.map((pro, index) => {
        if (pro.category === nextProps.item.category && pro.name !== nextProps.item.name) {
          sim.push(pro)
        }
      })

        const URL = window.location.hostname + '/productdetail?' + nextProps.item.name;
        // const URL = ''
        const TITLE = 'X2Lab Products Page : ' + nextProps.item.name;
        const face = 'https://www.facebook.com/sharer/sharer.php?u=' + URL + '&title=' + TITLE;
        const twitter = 'https://twitter.com/intent/tweet?url=' + URL + '&Title=' + TITLE;
        const link = 'https://www.linkedin.com/shareArticle?mini=true&url=' + URL + '&title=' + TITLE + '&source=' + window.location.hostname;
        if (this.state.facebook === '')
          this.setState({
            facebook: face,
            twitter: twitter,
            linkedin: link,
          })
      let sub = [...nextProps.item.subimage]
      sub.push({
        image: nextProps.item.mainimage
      })
      let resub = []
      resub.push(sub[1])
      resub.push({
        image: nextProps.item.mainimage
      })
      for ( let i = 2 ; i < sub.length - 1 ; i++){
        resub.push(sub[i])
      }
      this.setState({
        similar: sim,
        curImage: nextProps.item.mainimage,
        subImages: resub
      })
    }
  }
  /* eslint-disable */
  // componentDidMount() { 
  //   console.log('didmount')
  //   console.log(this.props)
  //   ///homepageurl
  //   const URL = window.location.hostname + '/productdetail?' + this.props.item.name;
  //   // const URL = ''
  //   const TITLE = 'Mark Product Hompage : ' + this.props.item.name;
  //   const face = 'https://www.facebook.com/sharer/sharer.php?u=' + URL + '&title=' + TITLE;
  //   const twitter = 'https://twitter.com/intent/tweet?url=' + URL + '&Title=' + TITLE;
  //   const link = 'https://www.linkedin.com/shareArticle?mini=true&url=' + URL + '&title=' + TITLE + '&source=' + window.location.hostname;
  //   if (this.state.facebook === '')
  //   setTimeout(() => { 
  //     this.setState({
  //       facebook: face,
  //       twitter: twitter,
  //       linkedin: link,
  //     })
  //   },10)
  // }
  render() {

    let photoIndex = this.state.curImageIndex
    let images = []
    this.state.subImages.map((img) => {
      images.push(img.image)
    })
    // var VV = this.state.special
    //this.feedState();
    return (
      <div className="Details">
        <div className="col col-lg-6 col-md-6 col-sm-12 col-sm-12 col-12">
          <div className="MainImage">
            <div className="ImageBox productwithdetail">
              <img 
                className="Bigmodal" 
                src={this.state.curImage}
                onClick={(e) => {
                  this.setState({
                    showmodal: true
                  })}
                }
              />
            </div>
            {this.render_sub_images()}
          </div>
        </div>
        <div className="col col-lg-6 col-md-6 col-sm-12 col-sm-12 col-12">
          <div className="luxury-item-title">
            <div className="title title-name">{this.props.item.title}</div>
            <div className="subtitle">{this.props.item.subtitle}</div>
          </div>
          <div className="luxury-item-title">
            <div className="title">{'Category'}</div>
            <div className="subtitle">{this.props.item.category}</div>
          </div>
          <div className="luxury-item-title">
            <div className="title">{'Price'}</div>
            <div className="subtitle">
              {
                this.props.item.price !== '0' ? 
                this.props.item.price
                :
                'RFQ'
              }
            </div>
          </div>
          <div className="luxury-item-title">
            <div className="title">{'Item No'}</div>
            <div className="subtitle">
              {this.props.item.item_no}
            </div>
          </div>

                    
          {/* <div className="luxury-item-title">
            <div className="title">{'Dimension'}</div>
            <div className="subtitle">{this.props.item.dimension}</div>
          </div> */}
          {/* <div className="luxury-item-title">
            <div className="title">{'Rating'}</div>
            <div className="subtitle">
              <div dangerouslySetInnerHTML={{__html: this.render_rating()}} />
            </div>
          </div> */}
          <div className="luxury-item-title">
            <div className="title">{'Share Product'}</div>
            <ul className="luxury-item-icons social-share-container">
                <li>
                <FacebookShareButton
                    url={this.state.facebook}
                    quote={this.props.item.name}
                    className="button" 
                    >
                    <i className="color-r social-share  fa fa-facebook"/>
                  </FacebookShareButton>
                </li>
                <li>
                <TwitterShareButton
                    url={this.state.twitter}
                    quote={this.props.item.name}
                    className="button" 
                  >
                    <i className="color-r social-share  fa fa-twitter"/>
                  </TwitterShareButton>
                </li>
                <li>
                <LinkedinShareButton
                      url={this.state.linkedin}
                      quote={this.props.item.name}
                      className="button" 
                    >
                      <i className="color-r social-share  fa fa-linkedin"/>
                    </LinkedinShareButton>
                </li>
              </ul>
          </div>
          {/* <div className="luxury-item-detail">
            <label className="title">Product Detail</label>
            <label className="detail">{this.props.item.detail}</label>
          </div> */}
        </div>
        <div className="col col-lg-12 col-md-12 col-sm-12 col-sm-12 col-12">
          <div className="luxury-item-title p-title">
            <div className="title-name">{`Product details for ${this.props.item.title}`}</div>
            {/* <div className="table-container">
              {
                this.renderTable()
              }
            </div> */}
            <div className="subtitle">
              {this.render_detail()}
            </div>
          </div>
        </div>
        {
            this.props.login === 'Login' ?
            <div></div>
            :
            <div className="col col-lg-12 col-md-12 col-sm-12 col-sm-12 col-12">
              <hr />
              <div className="luxury-item-title p-title">
                <div className="title-name">Additional Infomation (Secure)</div>
                <div className="subtitle">
                  {this.render_detail(1)}
                </div>
              </div>
            </div>
          }
                  
        {this.state.showmodal && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ showmodal: false })}
            onMovePrevRequest={() =>
              this.setState({
                curImage: this.state.subImages[(photoIndex + images.length - 1) % images.length].image,
                curImageIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                curImage: this.state.subImages[(photoIndex + 1) % images.length].image,
                curImageIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )} 
        <style jsx>{`

          .p-title {
            .title-name {
              margin-bottom: 20px !important;
            }
          }

          .productwithdetail {
            img {
              object-fit: contain !important;
            }
          }

          .similarItems {
            margin: 0 auto;
            margin-bottom: 70px !important;
            h2 {
              font-weight: 700;
              font-size: 24px;
              color: #052b6c;
            }
          }
          .luxury-item-detail {
            .detail {
              color: #676767;
              font-weight: 600;
              font-size: 16px;
            }
          }
          .color-r {
            color: #0098e4;
          }
          .social-share {
            font-size: 20px;
            margin-right: 15px;
          }
          .social-share-container {
            display: flex;
            list-style: none;
          }
          .Details {
            max-width: 75em;
            margin: 0 auto;
            padding: 0 20px;
            padding-top: 30px;
            display: flex;
            flex-wrap: wrap;
            .title {
              color: #052b6c;
              font-size: 20px;
              font-weight: 600;
            }
            .luxury-item-title {
              margin-bottom: 20px;
              .subtitle {
                color: #676767;
                font-weight: 600;
                font-size: 16px;
                img {
                  width: 80%;
                  max-height: 300px;
                  object-fit: cover;
                  border-radius: 10px;
                }
              }
              .title-name {
                font-weight: 700;
                font-size: 24px;
                color: #052b6c;
                margin-bottom: 17px;
              }
            }
            .col {
              padding: 15px;
            }
            .MainImage {
              width: 100%;
              text-align: center;
            }
            .ImageBox {
              height: 350px;
              width: 100%;
              img {
                width: 90%;
                max-height: 300px;
                object-fit: cover;
              }
            }
          }
          @below tablet {
            .Details {
              padding: 0px;
              .ImageBox {
                height: 200px;
                img {
                  max-height: 150px;
                }
              }
            }
          }
        `}</style>
      </div>
    )
  }
}

export default ProductWithDetail;
