import React from "react";
import { graphql, navigate } from "gatsby";
import PropTypes from "prop-types";
import { categories } from "../../config";
import { ThemeContext } from "../layouts";
import { Link } from "gatsby";
import Seo1 from "../components/Seo/Seo1"
import Categories from "../components/Category";
import ProductWithDetail from "../components/Product/ProductWithDetail";
import { connect } from 'react-redux';

class ProductDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      product: '',
      category: [],
      width: 0,
      height: 0,
      selectedcat: "",
      special: '',
      param: '',
      similar: [],
      totalProduct: []
    };
    this.image_click = this.image_click.bind(this)
    this.SelectCategory = this.SelectCategory.bind(this);
    this.render_feed = this.render_feed.bind(this)
  }

  image_click(name) {
    let sim = []
    this.state.totalProduct.map((toi, index) => {
      if (toi.category === this.state.product.category && toi.name !== name) {
        sim.push(toi)
      }
    })
    const URL = '/productdetail?' + name;
    window.location = URL ;
    this.setState({
      similar: sim
    })
  }

  componentDidMount() {
    import('react-leaf-carousel')
      .then((item) => {
        this.setState({
          special: item.default
        })
    })
    let param = this.props.location.search.split('?') 
    let pa = '';
    let cate = '';
    if(param.length >= 2) {
      pa = param[1]
      cate = pa.split('%20').join(' ')
    }
    let category = [];
    this.props.data.category.edges.map((item,key) => {
      const ca = item.node.frontmatter;
      category.push(ca)
    })
    this.setState({
      category: category
    });
    let to = []
    let pro = ''
    this.props.data.allMarkdownRemark.edges.map((item, key) => {
      const product = item.node.frontmatter;
      to.push(product)
      //if (product.name.indexOf(cate) === 0) {
      if (product.name.toLowerCase().trim() === cate.toLowerCase().trim()) {
        pro = product
        this.setState({
          product: product,
          selectedcat: product.category
        });
      }
    });
    this.setState({
      totalProduct: to
    })
    let sim = []
    to.map((toi, index) => {
      if (toi.category === pro.category && toi.name !== pro.name) {
        sim.push(toi)
      }
    })
    this.setState({
      similar: sim
    })
  }

  SelectCategory(key) {
    this.setState({
      selectedcat: categories[key]
    });
  }

  render_feed() {
    let param = this.props.location.search.split('?') 
    
    let pa = '';
    let cate = '';
    if (param.length < 2) return;
    if (param[1] === this.state.param) return;
    if(param.length >= 2) {
      pa = param[1]
      this.setState({
        param: pa
      })
      cate = pa.split('%20').join(' ')
    }
    let category = [];
    this.props.data.category.edges.map((item,key) => {
      const ca = item.node.frontmatter;
      category.push(ca)
    })
    this.setState({
      category: category
    });
    let to = [];
    let pro = '';
    let success = 0;
    this.props.data.allMarkdownRemark.edges.map((item, key) => {
      const product = item.node.frontmatter;
      to.push(product)
      //if (product.name.indexOf(cate) === 0) {
      if (product.name.toLowerCase() === cate.toLowerCase()) {  
        pro = product;
        success = 1;
        this.setState({
          product: product,
          selectedcat: product.category
        });
      }
    });
    if (!success) {
      this.setState({
        product: -1
      })
    }    
    this.setState({
      totalProduct: to
    })
    if (this.state.similar.length === 0) {
      let sim = []
      to.map((toi, index) => {
        if (toi.category === pro.category && toi.name !== pro.name) {
          sim.push(toi)
        }
      })
      this.setState({
        similar: sim
      })
    }
  }

  render() {
    //console.log('------Product Keywords------')
    //console.log(this.state.product.keys)
    var VV = this.state.special
    this.render_feed();
    return (
      <React.Fragment>
        <ThemeContext.Consumer>
          {theme => (
            <div className="product_container">
              {/* <Categories
                productdetail={1}
                name={this.state.product.name}
                category={this.state.product.category}
              /> */}
              <Categories
                categories = {this.state.category}
                route={1}
                selectedcat={this.state.selectedcat}
                onClick={this.SelectCategory}
              />
              {
                //this.state.product === '' ? 
                // if Product is not found, display category.
                this.state.product === -1 ?
                //<div className="noItem"><h2>Product Not Found!</h2></div>
                // {selectedcat} is always empty
                navigate('/products')
                :
                this.state.product === '' ?
                <div></div>:                
                <ProductWithDetail login = {this.props.loginStatus} total={this.state.totalProduct} item={this.state.product} />
              }
              {/* --- STYLES --- */}
              <div  className="similarItems">
                {
                  this.state.proudct !== ''&& this.state.product.category !== undefined ?
                  <h2>Other Products ({this.state.product.category.charAt(0).toUpperCase() + this.state.product.category.slice(1)})</h2>
                  :
                  <div></div>
                }
                {
                  VV === "" ?
                    <div>
                    </div>
                    :
                    <VV
                      breakpoints={[
                      {
                        breakpoint: 500,
                        settings: {
                          slidesToShow: 2,
                          slidesToScroll: 2,
                        },
                      },
                      {
                        breakpoint: 768,
                        settings: {
                          slidesToShow: 3,
                          slidesToScroll: 3,
                        },
                      },
                    ]}
                    // dots={true}
                    showSides={true}
                    sidesOpacity={.5}
                    sideSize={.1}
                    slidesToScroll={4}
                    slidesToShow={4}
                    scrollOnDevice={true}
                    animationDuration={1000}
                    autoCycle={true}
                  >
                    {
                      this.state.similar.map((product, index) => {
                        return (
                          <div key={index}>
                            <Link
                              to={`/productdetail?${product.name}`}
                              className="news-item"
                            >
                              <img
                                alt=''
                                src={product.mainimage}
                                onClick={(e) => this.image_click(product.name)}
                              />
                            </Link>
                            <h1 className="similarh1">{product.name}</h1>
                          </div>
                        )
                      })
                    }
                  </VV>
                }
              </div>
              <Seo1
                // postTitle = {this.state.product.name}
                // title is displayed in browser title bar; however it shows undefined in Skype preview. 
                // Show as Product Details in skype 
                postTitle = {this.state.product !== '' ? this.state.product.name : 'Product Details'}
                postDescription = {'X2Lab Products Listing'}
                keywords = {this.state.product.keys}
              />
              <style jsx>{`
                .noItem {
                  display: flex;
                  justify-content: center;
                  margin-top: 70px;
                }   
                .similarItems {
                  margin-bottom: 70px;
                  margin-top: 20px;
                  h2 {
                    font-weight: 700;
                    font-size: 24px;
                    color: #052b6c;
                    text-align: center;
                    margin-bottom: 30px;
                  }
                }
                .products {
                  max-width: 80em;
                  margin: 0 auto;
                  padding: 0 20px;
                  padding-top: 30px;
                  display: flex;
                  flex-wrap: wrap;
                }
                .product_container {
                  padding: 0rem;
                  margin: 0 auto;
                }
                @from-width desktop {
                }
              `}</style>
            </div>
          )}
        </ThemeContext.Consumer>
      </React.Fragment>
    );
  }
}

ProductDetail.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object
};

function mapStateToProps(state) {
  return { loginStatus: state.loginReducer.login }
}

export default connect(mapStateToProps)(ProductDetail);

/*eslint-disable */
export const pageQuery = graphql`
  query ProductItemQuery {
    category: allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "category-item"}}}) {
      edges {
        node {
          id
          frontmatter {
            title
            detail
            mainimage
            order
          }
        }
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "store-item" } }}
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            name
            subtitle
            category
            price
            detail
            item_no
            keys
            security
            mainimage
            dimension
            rating
            subimage {
              image
            }
            table {
              cell {
                item
              }
            }
          }
        }
      }
    }
  }
`